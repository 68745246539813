.editKeypad{
    text-align: left;
}

.backButton {
    display: inline-flex;
    align-items: center;
    padding: 10px;
    margin-left: 10px;
    cursor: pointer;
}
.backButton >span {
    margin-right: 5px;
}

.updateConfirmButtons {
    display:flex;
    width:100%;
    gap:20px;
}
.updateConfirmButtons >* {
    flex:1;

}