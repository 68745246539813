.keypadListItem {
    display: flex;
    justify-content: space-between;
}

.keypadInfo {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
}

.options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0;
    width: max-content;
}

.option {
    background: #f7f7f7;
    border-radius: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
    cursor: pointer;
}

.option:active {
    background: #f3f3f3;
}

.name {
    font-size: 1.2rem;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
}

.comment {
    text-overflow: ellipsis;
    overflow: hidden;
}