.headerContainer {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    padding-bottom: 15px;
    z-index: 10;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background: white;
    z-index: 10;
    box-shadow: 0 0 5px 0px #aaa;
    align-items: center;
}

.header .title {
    font-weight: 400;
}

.openNavigation {
    padding: 10px;
    background: #eee;
    border-radius: 10px;
}

.nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.nav .link {
    padding: 15px;
    border-radius: 10px;
    text-decoration: none;
    color: #333;
    transition: .3s;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    gap: 15px;
}

.nav .link:active {
    background: #eee;
}


.nav .link.active {
    background: #333;
    color: #eee;
}