.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    background: #00000044;
    z-index: 10;
    transition: .3s;
}

.backdrop.open {
    opacity: 1;
    pointer-events: all;
}

.drawer {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    padding: 10px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, .3);
    transition: .3s;
    transform: translateX(100px);
    width: 250px;
    max-width: calc(100vw - 40px);
    display: flex;
    flex-direction: column;
}

.backdrop.open .drawer {
    transform: translateY(0);
}

.drawer:has(.header) {
    padding-top: 0;
}

.header {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closeButton {
    padding: 10px;
    background: #eee;
    border-radius: 10px;
}