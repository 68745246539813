.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    background: #00000044;
    z-index: 10;
    transition: .3s;
}

.backdrop.open {
    opacity: 1;
    pointer-events: all;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 30px 0 #00000055;
    transition: .3s;
    transform: translateY(30px);
    min-width: 250px;
    max-width: calc(100vw - 40px);
}

.backdrop.open .modalContent {
    transform: translateY(0);
}

.header{
    text-align: center;
    margin-bottom: 20px;
}

.header>* {
    margin: 0;
    padding: 0;
}