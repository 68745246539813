.container {
    border-radius: 15px;
    background: #eee;
    padding: 15px;
    margin: 0 20px 15px;
}

.container.error {
    color: #912d2b;
    border-radius: 10px;
    background: #fff6f6;
    border: 2px solid #e0b4b4;
    margin: 20px 0 0;
}
.container.success {
    color: #1a531b;
    border-radius: 10px;
    background: #fcfff5;
    border: 2px solid #a3c293;
    margin: 20px 0 0;
}