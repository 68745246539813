.eyeContainer {
    aspect-ratio: 1/1;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding:5px;
}

.eye {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
    height:100%;
    width: 100%;
    border-radius: 50%;
}