.button {
    padding: 10px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    border: 2px solid #222;
}

.button.block {
    width: 100%;

}

.button:disabled {
    cursor: auto;
}

.button.primary {
    background: #222;
    color: white;
}

.button.primary:disabled {
    border: 2px solid #444;
}

.button.primary:disabled {
    background: #444;
}

.button.secondary {
    background: white;
}
.button.secondary:disabled {
    background: #eee;
    color: #444;
}

.button .loadContainer {
    width: 100px;
    max-width: 0;
    display: inline-block;
    overflow: hidden;
    color: white;
    transition: .3s;
    margin-right: 0;
    opacity: 0;
}

.button .loadContainer.loading {
    opacity: 1;
    max-width: 20px;
    margin-right: 10px;
}