.wrapper {
    overflow-x: hidden;
}

.wrapper .slider {
    width: 200%;
    display: flex;
    transition: .3s;
}

.wrapper .slider>* {
    width: 50%;

}

.wrapper .slider.selected {
    margin-left: -100%;
}