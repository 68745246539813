.codeGenerator {
    height: 100%;
}

.radioButtonContainer:first-child label {
    border-radius: 10px 10px 0 0;
    border-top-width: 2px;
}

.radioButtonContainer:last-child label {
    border-radius: 0 0 10px 10px;
    border-bottom-width: 2px;
}

.radioButtonContainer label {
    transition: .2s;
    width: 100%;
    padding: 10px;
    border: 1px solid #222;
    border-left-width: 2px;
    border-right-width: 2px;
    outline: none;
    background: white;
    line-height: 15px;
}

.radioButtonContainer input {
    opacity: 0;
    z-index: -1;
    position: absolute;
}

.radioButtonContainer input:checked+label {
    background: #222;
    color: white;
}

.radioButtonContainer input:disabled+label {
    background: #eee;
}

.radioButtonContainer input:disabled:checked+label {
    background: #444;
}

.code {
    letter-spacing: .3em;
}

.address {
    margin-top: 10px;
}
