* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  max-width: 500px;
  margin: 0 auto;
  position: relative;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  text-align: center;
  padding-top: 10px;
  margin: 0 20px 10px;
}

fieldset {
  border: none;
}

fieldset:disabled * {
  border-color: #444 !important;
}