.formElementContainer {
    text-align: left;
}

.formElementContainer {
    margin: 0;
}
.formElementContainer:has( +.formElementContainer ){
    margin: 0 0 15px;
}

.formElementContainer label {
    display: block;
}

.formElementContainer input,
.formElementContainer select {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #222;
    outline: none;
    transition: .3s;
    background: white;
}

.formElementContainer :has(+.errorMessageContainer.show) input,
.formElementContainer :has(+.errorMessageContainer.show) select {
    border: 2px solid red;
}

.formElementContainer .errorMessageContainer {
    max-height: 0;
    transition: .3s;
    z-index: -1;
    color: transparent;
}

.formElementContainer .errorMessageContainer.show {
    color: red;
    max-height: 63px;
}

.formElementContainer .errorMessage {
    display: block;
}

.formElement {
    position: relative;
    z-index: 1;
}